<template>
  <div class="details">
    <div class="title">{{data.cname}}</div>
    <div class="line" v-if="data.author">
      <div class="line-title">【 作&nbsp&nbsp&nbsp&nbsp&nbsp者 】</div>
      <div class="line-text" v-html="filterA(data.author)"></div>
    </div>
    <div class="line" v-if="data.author_department">
      <div class="line-title">【 作者单位 】</div>
      <div class="line-text" v-html="filterA(data.author_department)"></div>
    </div>
    <div class="line" v-if="data.zipcode">
      <div class="line-title">【 邮&nbsp&nbsp&nbsp&nbsp&nbsp编 】</div>
      <div class="line-text" v-html="data.zipcode"></div>
    </div>
    <div class="line" v-if="data.address">
      <div class="line-title">【 地&nbsp&nbsp&nbsp&nbsp&nbsp址 】</div>
      <div class="line-text" v-html="data.address"></div>
    </div>
    <div class="line" v-if="data.research_disease">
      <div class="line-title">【 研究疾病 】</div>
      <div class="line-text" v-html="data.research_disease"></div>
    </div>
    <div class="line" v-if="data.coverage">
      <div class="line-title">【 适用范围 】</div>
      <div class="line-text" v-html="data.coverage"></div>
    </div>
    <div class="line" v-if="data.page">
      <div class="line-title">【 页&nbsp&nbsp&nbsp&nbsp&nbsp码 】</div>
      <div class="line-text" v-html="data.page"></div>
    </div>

    <div class="line" v-if="data.cn_keyword">
      <div class="line-title">【中文关键词】</div>
      <div class="line-text" v-html="filterA(data.cn_keyword)"></div>
    </div>
    <div class="line" v-if="data.en_keyword">
      <div class="line-title">【英文关键词】</div>
      <div class="line-text" v-html="filterA(data.en_keyword)"></div>
    </div>
    <div class="line" v-if="data.category_path">
      <div class="line-title">【 文献类型 】</div>
      <div class="line-text" v-html="filterA(data.category_path)"></div>
    </div>
    <div class="line" v-if="data.source">
      <div class="line-title">【 出&nbsp&nbsp&nbsp&nbsp&nbsp处 】</div>
      <div class="line-text" v-html="data.source"></div>
    </div>
    <div class="line" v-if="data.getissues">
      <div class="line-title">【 年&nbsp卷&nbsp期 】</div>
      <div class="line-text" v-html="data.getissues"></div>
    </div>
    <div class="column" v-if="data.abstract">
      <div class="line-title">【 摘&nbsp&nbsp&nbsp&nbsp&nbsp要 】</div>
      <div class="line-text" v-html="data.abstract"></div>
    </div>
    <div class="line" v-if="data.download">
      <div class="line-title">【 操&nbsp&nbsp&nbsp&nbsp&nbsp作 】</div>
      <div class="line-text">
        <a href="" @click.prevent="onDownload(data, '5')">下 载</a>
      </div>
    </div>
    <div class="foot-details" v-if="data.disease">
      <div class="line-title">【 相关疾病 】</div>
      <div class="line-text">
        <ul v-html="data.disease"></ul>
      </div>
    </div>
  </div>
</template>

<script>
  import mixins from '../../mixins'
  export default {
    name: "confirmationDetails",
    mixins: [
      mixins
    ],
    data(){
      return {
        type: '5',
      }
    },
    created() {
      if (this.$route.query.id || this.$route.query.ID){
        this.getData({ id: this.$route.query.id || this.$route.query.ID, type: this.type })
      }
    }
  }
</script>
